:root{
    --primary: #ef59a1!important;
    --secondary: #356396!important;
    --light:#ef59a11a!important;
    --accent: #5353fba1!important;
    --text:#72849a!important;
    --active-list:#455560!important;
    --danger-alt:#ff6b72!important;
}

/* custom modifaed theme */

.scrollbar_smooth{overflow-y: scroll;overflow-x: hidden;scroll-behavior: smooth;}
.scrollbar_smooth::-webkit-scrollbar{width: 6px;background-color: transparent;}
.scrollbar_smooth::-webkit-scrollbar-thumb{width: 100%;background-color: var(--primary);border-radius: 5px;}
.scrollbar_smooth::-webkit-scrollbar-corner{background-color: transparent;color: transparent;}


.bg-custom-1{background-color: #eaf4fe!important;transition: all 0.30s ease;}
.bg-primary{background-color: var(--primary);}
.ant-btn-primary{background-color: var(--primary);}
.ant-btn-primary:not(:disabled):hover{background-color: var(--secondary);}
.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{color: var(--primary);}
.ant-menu-light .ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected),
.ant-menu-light .ant-menu-submenu-title:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected){color: var(--primary);}
.ant-menu-light .ant-menu-item-selected{color:var(--primary)}
.ant-menu-light .ant-menu-item-selected{background-color: var(--light);}
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active{background-color: var(--light);}
.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active{background-color: var(--light);}
.ant-menu-light.ant-menu-inline .ant-menu-item-selected::after{border-color: var(--primary);}
.ant-spin .ant-spin-dot-item{background-color: var(--primary)!important;}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){background-color: var(--light);}
.ant-select-focused .ant-select-selector,
.ant-select-open.ant-select-show-search .ant-select-selector{border: 1px solid var(--primary)!important;}
.ant-input-affix-wrapper-focused,
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector{border-color: var(--primary)!important;}
h5.input_img_text > span.text-info{color: var(--primary)!important;}
th.ant-table-cell,
thead .ant-table-column-title{text-transform: uppercase!important;}
.ant-spin {color: var(--primary);}
.border-button:hover,
.edit-button:hover{color: var(--primary)!important;}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{background-color: var(--primary)!important;}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before{background-color: var(--primary);}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{border-color: var(--primary)}
.ant-input:hover,
.ant-input-number:hover,
.ant-input-number:focus, .ant-input-number-focused{border-color: var(--primary)!important;}
.ant-input:focus, .ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:hover,
.ant-picker-focused,
.ant-picker:hover,
.ant-select:hover,
.ant-select:focus,
.ant-select.ant-select-in-form-item.ant-select-multiple.ant-select-show-arrow.ant-select-open.ant-select-show-search:hover,
.ant-input:hover,
.ant-input-number:hover,
.ant-input-number:focus, .ant-input-number-focused{border-color: var(--primary)!important;}
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused,
.ant-input:focus, .ant-input-focused{box-shadow: 0 0 0 2px rgba(239, 89, 161, 0.218)}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner{border-color: var(--primary);}
.ant-checkbox-checked .ant-checkbox-inner {background-color: var(--primary);border-color: var(--primary);}
.ant-checkbox-indeterminate .ant-checkbox-inner:after{background-color: var(--primary);}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner{background-color: var(--primary);}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after{border-color: var(--primary);}
.ant-checkbox-checked:after{border-color: var(--primary);}
.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected:hover>th,
.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected:hover>td,
.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >th,
.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td{background-color: var(--light);}
.ant-breadcrumb a:hover{color: var(--primary);}
.ant-pagination .ant-pagination-item-active{background-color: var(--primary);}
.ant-radio-wrapper:hover .ant-radio-wrapper,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-radio-wrapper .ant-radio-checked::after{border-color: var(--primary)!important;}
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner{background-color: var(--primary);border-color: var(--primary);}
.ant-picker-dropdown .ant-picker-today-btn{color: var(--primary);}
.ant-picker-dropdown .ant-picker-header-view button:hover,
.ant-picker-dropdown .ant-picker-today-btn:hover{color: var(--hover);}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{border-color: var(--primary);}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner{background-color: var(--primary);}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{background-color: var(--primary)!important;}
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before{background-color: var(--accent);}
.ant-picker-focused,
.ant-picker:hover{border-color: var(--primary);}
.ant-picker-now-btn{color: var(--primary);}
.ant-tabs .ant-tabs-tab:hover,
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color: var(--primary);}
/* .ant-menu-item-only-child:has(a.active) {background-color:var(--light);color:var(var(--primary))!important;} */



/* --------Global Css========> */
.bg-cancel{background-color: var(--danger-alt)!important;}
ul{padding: 0;}
ul li{list-style-type: none;}

/* new class css */
.gap-1{gap: 4px;}
.gap-2{gap: 8px;}
.gap-3{gap: 12px;}
.gap-4{gap: 16px;}
.gap-5{gap: 20px;}
.justify-content-evenly{justify-content: space-evenly;}
.cursor-pointer{cursor: pointer;}

/* checkbox */

.check_box {border: 2px solid var(--primary);width: 20px;height: 20px!important;border-radius: 3px;top: 3px;right: 3px;background-color: white;}
.check_box_alt {border: 2px solid #e6ebf1;width: 20px;height: 20px!important;border-radius: 3px;top: 3px;right: 3px;background-color: white;}
.check_box_alt:hover {border: 2px solid var(--primary);width: 20px;height: 20px!important;border-radius: 3px;top: 3px;right: 3px;background-color: white;}
.check_box_alt.active{background-color: var(--primary);border-color: var(--primary);}

/* ant design custom design=============> */

.ant-menu .ant-menu-item{border-radius: 0;}


/* ==============> */
.left-0{left: 0;}
.top-0{top: 0;}
.shadow{box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05)!important;}
.shadow-sm{box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)!important;}
.shadow-md{box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)!important;}
.show{display: flex;}
.hide{visibility: hidden!important;opacity:0;transition:all 0.30s ease;}
.disabled-btn{cursor: not-allowed;border-color: #e6ebf1;color: rgba(69, 85, 96, 0.25);background-color: rgba(69, 85, 96, 0.04);box-shadow: none;}
.scrollbar_smooth{overflow-y: scroll;}
.scrollbar_smooth.scrollbar_smooth_y{overflow-y: scroll;overflow-x: hidden;}
.scrollbar_smooth::-webkit-scrollbar{width: 6px;background-color: transparent;}
.scrollbar_smooth::-webkit-scrollbar-thumb{width: 100%;background-color: var(--primary);border-radius: 5px;}
.scrollbar_smooth::-webkit-scrollbar-corner{background-color: transparent;color: transparent;}
/* form sec */

.no_data_box{border-radius: 10px;position: relative;}
.no_data_box div{color: rgba(128, 128, 128, 0.536);}
.loading{width: 100%;height: 100%;position: absolute;backdrop-filter: blur(1px);top:0;left: 0;}
/* full screen loading-----> */
.full_screen_loading{background-color: rgba(0, 0, 0, 0.255);z-index: 1000;backdrop-filter: blur(2px);}

/* ---------------------- */

.login-logo{width: 50%;}
.site_logo{object-fit: contain;}
.logo img {width: 170px!important;margin: auto;}

/* ----delete popup---- */

.delete_popup{background-color: rgba(0, 0, 0, 0.255);z-index: 1000;backdrop-filter: blur(2px)}
.delete_popup .popup{border-radius: 5px;width: 300px;}
.delete_popup .popup .dialogue{line-height: 20px;}
.delete_popup .popup .del_btn{background-color: var(--danger-alt);}
.delete_popup .popup .del_btn:focus-visible{outline: 4px solid #ff848ab1;}
.delete_popup .popup .del_btn:hover{border: 1px solid #ff757c!important;}

/* filter section */
.table-filter-list{padding: 7px 20px;border-radius: 10px;}
.table-filter-list:hover{background-color: #f0f7ff;}
.filter_list_sec .filter_list{max-height: 165px;overflow-y: scroll;border-bottom: 1px solid rgba(128, 128, 128, 0.311);}
.filter_list_sec .filter_list::-webkit-scrollbar{width: 6px;background-color: transparent;}
.filter_list_sec .filter_list::-webkit-scrollbar-thumb{width: 100%;background-color: var(--primary);border-radius: 5px;}
.filter_list_sec .filter_list::-webkit-scrollbar-corner{background-color: transparent;color: transparent;}

/* / UPLOAD IMAGE COMPONENT / */
.file_upload_sec {border: 1px dashed gray; height: 150px;cursor: pointer;}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon,
.file_upload_sec .img_icon_right{width: 50px;height: 50px;display: flex;}

.file_upload_sec .img_icon_left,
.file_upload_sec .img_icon_right{filter: grayscale(1);opacity: 0.6;}

.file_upload_sec .img_icon_left{right: -50px;transition: all 0.30s ease;}
.file_upload_sec .img_icon_right{left: -50px;transition: all 0.30s ease;}
.file_upload_sec:hover .img_icon_left{right: -20px;transform: rotate(-20deg);transition: all 0.30s ease;}
.file_upload_sec:hover .img_icon_right{left: -20px;transform: rotate(20deg);transition: all 0.30s ease;}

.file_upload_sec .img_icon{z-index: 1;}
.file_upload_sec .img_icon img{width: 100%;height: 100%;object-fit: contain; object-position: center;filter: hue-rotate(100deg);}
.file_upload_sec .uploaded_img{object-fit: cover;object-position: center;}

.file_upload_input{width: 100%;height: 100%;opacity: 0;z-index: 2;cursor: pointer;}
.img_delete_sec{top:0;left: 0;background-color: rgba(174, 173, 173, 0.41);}
.delete_btn{border: 1px solid #ff6b72;display: flex;align-items: center;justify-content: center;width: 40px; height: 40px;border-radius: 50%;background-color: #ff6b72;transition:all 0.30s ease;}
.delete_btn:hover{scale: 1.2;transition:all 0.30s ease;}
/* / =====================> / */

/* Active Inactive button */

.activeBtn{color: #08979c;background: #e6fffb;border-color: #87e8de;}
.activeBtn:hover{color: #08979c !important;background: #e6fffb !important;border-color: #87e8de !important;}
.inActiveBtn{color: #d4380d;background: #fff2e8 ;border-color: #ffbb96 ;}
.inActiveBtn:hover{background: #fff2e8 !important; color: #d4380d !important;}

/* ans sec */

.ant-radio-wrapper {width:100%;}
.ant-radio-wrapper > span:last-child{width: 100%;}
.quill > div:first-child{border-radius: 10px 10px 0 0 ;}
.quill > div:last-child{border-radius: 0 0 10px 10px;height:100px;max-height: 250px;}
.multiple-datepicker{margin: 1px;width: 32px;height: 32px;border-radius: 3px;}
.ant-picker-cell-selected .multiple-datepicker,
.multiple-datepicker.selected{background-color: var(--primary);color: white;}
.ant-picker-dropdown .ant-picker-cell:hover .multiple-datepicker{border:1px solid #ef59a1;color: var(--primary);}
.ant-picker-cell-selected:hover .multiple-datepicker{color: white!important;}
.selected-date-time-menu .close{color: white;background-color: var(--danger-alt);width: 25px;height: 25px;border-radius: 3px;}
.selected-date-time-menu li.item{background-color: var(--light);border-radius: 5px;}
.selected-date-time-sec{width: 200px;height: 100%;top: 0;left: 100%;background-color: white;border-radius: 10px;cursor:pointer;}
.multiple-datepicker-sec .ant-picker{height: 100%;}
.multiple-datepicker-sec .date-item{background-color: var(--light);border-radius: 3px;margin-right:3px;font-size: 12px;margin-bottom: 2px;}
.datepicker-sec{width: 100%;min-height: 40px;position: absolute;top: 0;left: 0;opacity: 0;}

/* answer-sec */
.answer-sec{height: 400px;}
.answer-sec .question{background-color: var(--light);min-height: 40px;border: 1px dashed var(--primary);border-left: 5px solid var(--primary);}
.answer-sec .answers .item{background-color: #e2e8e8;min-height: 40px;}
.answer-sec .item.correct{background-color: #1dac00;color: white;}
.answer-sec .answers .item.right{background-color: #1dac0021;}
.answer-sec .answers .item.wrong{background-color: #ff6b7221;}

/* / ---------------Scrollbar------------------------ / */
/* / For WebKit Browsers (Chrome, Safari) / */
::-webkit-scrollbar {
    width: 12px; 
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: var(--primary) ; 
    border-radius: 6px; 
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  /* / For Firefox / */
  .scrollBar html {
    scrollbar-width: thin; 
    scrollbar-color: var(--primary)  #f1f1f1; 
  }
